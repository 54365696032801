<template lang="pug">
tr.custom-field-list-item.animated.fadeIn
  td {{ customField.id }}
  td {{ customField.key }}
  td {{ customField.name || customField.description}}
  td {{ customField.type }}
  td {{ customField.length }}
  td
    .custom-field-value(
      v-for = '{ classifierValue, isEnabled } in  customField.defaultValues'
      :key= 'classifierValue'
      :class = '{ "text-strikethrough": !isEnabled }'
    ) {{ classifierValue }}
  td: fi-switch(
    :value="customField.enabled"
    read-only
  )
  td: fi-switch(
    :value="customField.editable"
    read-only
  )
  td
    .btn-toolbar
      .btn-group.btn-group-sm
        router-link.btn.btn-link(:to = '{ name: "CustomFieldEdit", params: { id: customField.id } }')
          i.far.fa-edit.fa-fw.fa-lg
</template>

<script>
import FiSwitch from '@/components/FiSwitch'

export default {
  name: 'custom-field-list-item',
  components: { FiSwitch },
  props: {
    customField: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
