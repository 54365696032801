<template lang="pug">
.kyc-custom-field-list.animated.fadeIn
  .card
    .card-header
      | {{ $t('title') }}
      router-link.float-right(
        to = 'new'
        append
      ) {{ $t('newLink') }}
    .card-body
      fi-table(
        :fields      = 'fields'
        :items       = 'sortClassifiers'
        loader       = 'custom:fields:fetch'
        :empty-label = '$t("notFound")'
      )
        template(v-slot = '{ items }')
          k-custom-field-list-item(
            v-for         = 'item in items'
            :key          = 'item.id'
            :customer-field = 'item'
            :custom-field   = 'customFieldById(item.customFieldId)'
          )

</template>

<script>
import FiTable from '@/components/FiTable'
import KCustomFieldListItem from '@/views/admin/customFields/KCustomFieldListItem'
import { mapActions, mapState, mapGetters } from 'vuex'

export default {
  name: 'kyc-custom-field-list',

  components: { KCustomFieldListItem, FiTable },

  computed: {
    ...mapState('customFields', ['KYCCustomFields']),
    ...mapGetters('customFields', ['customFieldById']),
    sortClassifiers () {
      const list = JSON.parse(JSON.stringify(this.KYCCustomFields))
      // list.forEach((current) => {
      //   current.customName = this.customFieldById(current.customFieldId).name
      // })
      const sortList = list.sort(function (a, b) {
        const nameA = a.description
        const nameB = b.description
        if (nameA < nameB) return -1
        if (nameA > nameB) return 1
        return 0
      })
      return sortList
    },
    fields () {
      return [
        {
          key: 'fieldOrder',
          label: this.$t('order')
        },
        {
          key: 'key',
          label: this.$t('key')
        },
        {
          key: 'name',
          label: this.$t('name')
        },
        {
          key: 'required',
          label: this.$t('required')
        },
        {
          key: 'enabled',
          label: this.$t('enabled')
        },
        {
          key: 'enabledPortal',
          label: this.$t('enabledPortal')
        },
        {
          key: 'customerSector',
          label: this.$t('customerSector')
        },
        {}
      ]
    }
  },

  async created () {
    await this.loadCustomFields()
    await this.loadKYCCustomFields()
  },

  methods: {
    ...mapActions('customFields', ['loadKYCCustomFields', 'loadCustomFields'])
  }
}
</script>

<style scoped>

</style>


<i18n>
en:
  title:      "KYC fields"
  newLink:    "+ New KYC field"
  order:      "Order"
  key:        "Key"
  name:       "Name"
  type:       "Type"
  length:     "Length"
  values:     "Values"
  enabled:    "Enabled"
  editable:   "Editable"
  notFound:   "No custom fields defined"
  customerSector:     "Customer sector"
  enabledPortal:      "Portal"
  required:           "Required"
et:
  title:      "KYC fields"
  newLink:    "+ New KYC field"
  order:      "Order"
  key:        "Key"
  name:       "Name"
  type:       "Type"
  length:     "Length"
  values:     "Values"
  enabled:    "Enabled"
  editable:   "Editable"
  notFound:   "No custom fields defined"
  customerSector:     "Customer sector"
  enabledPortal:      "Portal"
  required:           "Required"
ru:
  title:      "KYC fields"
  newLink:    "+ New KYC field"
  order:      "Order"
  key:        "Key"
  name:       "Name"
  type:       "Type"
  length:     "Length"
  values:     "Values"
  enabled:    "Enabled"
  editable:   "Editable"
  notFound:   "No custom fields defined"
  customerSector:     "Customer sector"
  enabledPortal:      "Portal"
  required:           "Required"
</i18n>
