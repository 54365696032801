<template lang="pug">
.custom-field.animated.fadeIn
  v-loading(:loader = 'loading')
    .text-center(slot = 'spinner'): i.fa.fa-spinner.fa-pulse.fa-lg.m-4
    form.form-horizontal.animated.fadeIn(
      novalidate
      @submit.prevent = 'onSubmit'
    )
      .card
        .card-header {{ header }}
        .card-body
          .row
            .col-lg-6
              fi-form-field(:label = '$t("key")')
                input.form-control.form-control-sm(v-model = 'customFieldData.key')
              fi-form-field(:label = '$t("type")')
                fi-select(
                  v-model = "customFieldData.type"
                  :options = "typeOptions"
                )
              fi-form-field(:label = '$t("name")')
                input.form-control.form-control-sm(v-model = 'customFieldData.name')
              fi-form-field(:label = '$t("values")')
                custom-field-value-list(v-model="customFieldData.defaultValues")
            .col-lg-6
              fi-form-field(:label = '$t("description")')
                input.form-control.form-control-sm(v-model = 'customFieldData.description')
              fi-form-field(:label = '$t("size")')
                input.form-control.form-control-sm(v-model = 'customFieldData.length')
              fi-form-field(:label = '$t("editable")')
                fi-switch(
                  v-model   = 'customFieldData.editable'
                )
              fi-form-field(:label="$t('enabled')")
                fi-switch(
                  v-model   = 'customFieldData.enabled'
                )
          .row
            .col-lg
              button.btn.btn-primary.mb-3(
                type      = 'submit'
                :disabled = 'saving'
              ) {{ $t('common:save') }}
                i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'saving')
              | &nbsp;
              button.btn.btn-secondary.mb-3(
                :disabled      = 'saving'
                @click.prevent = 'onCancel'
              ) {{ $t('common:cancel') }}
</template>


<script>
import FiFormField from '@/components/FiFormField'
import FiSelect from '@/components/FiSelect'
import FiSwitch from '@/components/FiSwitch'
import { mapActions, mapState } from 'vuex'
import pick from 'lodash/pick'
import CustomFieldValueList from '@/views/admin/customFields/CustomFieldValueList'

export default {
  name: 'custom-field',

  components: { CustomFieldValueList, FiSwitch, FiSelect, FiFormField },

  props: {
    id: {
      type: [Number, String],
      default: ''
    }
  },

  i18nOptions: {},

  data: () => ({
    customFieldData: {
      key: null,
      name: null,
      description: null,
      type: '',
      enabled: false,
      editable: false,
      length: null,
      defaultValues: []
    }
  }),

  computed: {
    ...mapState('customFields', ['customField']),
    isNew () {
      return !this.id
    },
    typeOptions () {
      return ['NUMBER', 'UUID', 'TEXT', 'DATE', 'TIMESTAMP', 'BOOLEAN']
    },
    header () {
      if (this.isNew) {
        return this.$t('newTitle')
      } else {
        return this.$t('editTitle', { id: this.id })
      }
    },
    loading () {
      return [`custom:field:${this.id}:fetch`]
    },
    saving () {
      return this.$vueLoading.isLoading(this.isNew ? 'custom:field:save' : `custom:field:${this.id}:save`)
    }
  },

  watch: {
    id: {
      async handler (id) {
        await this.loadCustomField({ id })
        this.customFieldData = {
          ...this.customFieldData,
          ...pick(this.customField, Object.keys(this.customFieldData))
        }
      },
      immediate: true
    }
  },

  methods: {
    ...mapActions('customFields', ['saveCustomField', 'loadCustomField', 'updateCustomField']),
    async onSubmit () {
      const { id, customFieldData } = this
      if (this.isNew) {
        await this.saveCustomField({ customFieldData })
      } else {
        await this.updateCustomField({ customFieldData, id })
      }
      this.$router.push({ name: 'CustomFieldList' })
    },
    onCancel () {
      this.$router.push({ name: 'CustomFieldList' })
    }
  }
}
</script>


<i18n>
en:
  newTitle:    "New custom field"
  editTitle:   "Edit custom field #{{ id }}"
  order:       "Order"
  key:         "Key"
  name:        "Name"
  description: "Description"
  type:        "Type"
  size:        "Size"
  values:      "Values"
  value:       "Value"
  enabled:     "Enabled"
  editable:    "Editable"
et:
  newTitle:    "New custom field"
  editTitle:   "Edit custom field #{{ id }}"
  order:       "Order"
  key:         "Key"
  name:        "Name"
  description: "Description"
  type:        "Type"
  size:        "Size"
  values:      "Values"
  value:       "Value"
  enabled:     "Enabled"
  editable:    "Editable"
ru:
  newTitle:    "New custom field"
  editTitle:   "Edit custom field #{{ id }}"
  order:       "Order"
  key:         "Key"
  name:        "Name"
  description: "Description"
  type:        "Type"
  size:        "Size"
  values:      "Values"
  value:       "Value"
  enabled:     "Enabled"
  editable:    "Editable"
</i18n>
