<template lang="pug">
.custom-field-list.animated.fadeIn
  .card
    .card-header
      | {{ $t('title') }}
      router-link.float-right(
        to = 'new'
        append
      ) {{ $t('newLink') }}
    .card-body
      fi-table(
        :fields      = 'fields'
        :items       = 'sortClassifiers'
        loader       = 'custom:fields:fetch'
        :empty-label = '$t("notFound")'
      )
        template(v-slot = '{ items }')
          custom-field-list-item(
            v-for         = 'item in items'
            :key          = 'item.id'
            :custom-field = 'item'
          )

</template>

<script>
import FiTable from '@/components/FiTable'
import CustomFieldListItem from '@/views/admin/customFields/CustomFieldListItem'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'custom-field-list',

  components: { CustomFieldListItem, FiTable },

  computed: {
    ...mapState('customFields', ['customFields']),
    sortClassifiers () {
      const list = JSON.parse(JSON.stringify(this.customFields))
      const sortList = list.sort(function (a, b) {
        const nameA = a.description
        const nameB = b.description
        if (nameA < nameB) return -1
        if (nameA > nameB) return 1
        return 0
      })
      return sortList
    },
    fields () {
      return [
        {
          key: 'order',
          label: this.$t('order')
        },
        {
          key: 'key',
          label: this.$t('key')
        },
        {
          key: 'name',
          label: this.$t('name')
        },
        {
          key: 'type',
          label: this.$t('type')
        },
        {
          key: 'length',
          label: this.$t('length')
        },
        {
          key: 'values',
          label: this.$t('values')
        },
        {
          key: 'enabled',
          label: this.$t('enabled')
        },
        {
          key: 'editable',
          label: this.$t('editable')
        },
        {}
      ]
    }
  },

  created () {
    this.loadCustomFields()
  },

  methods: {
    ...mapActions('customFields', ['loadCustomFields'])
  }
}
</script>

<style scoped>

</style>


<i18n>
en:
  title:      "Custom fields"
  newLink:    "+ New custom field"
  order:      "Order"
  key:        "Key"
  name:       "Name"
  type:       "Type"
  length:     "Length"
  values:     "Values"
  enabled:    "Enabled"
  editable:   "Editable"
  notFound:   "No custom fields defined"
et:
  title:      "Custom fields"
  newLink:    "+ New custom field"
  order:      "Order"
  key:        "Key"
  name:       "Name"
  type:       "Type"
  length:     "Length"
  values:     "Values"
  enabled:    "Enabled"
  editable:   "Editable"
  notFound:   "No custom fields defined"
ru:
  title:      "Custom fields"
  newLink:    "+ New custom field"
  order:      "Order"
  key:        "Key"
  name:       "Name"
  type:       "Type"
  length:     "Length"
  values:     "Values"
  enabled:    "Enabled"
  editable:   "Editable"
  notFound:   "No custom fields defined"
</i18n>
