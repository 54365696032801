<template lang="pug">
tr.k-custom-field-list-item.animated.fadeIn
  td {{ customerField.fieldOrder }}
  td {{ customField.key }}
  td {{ customField.name }}
  td: fi-switch(
    :value="customerField.required"
    read-only
  )
  td: fi-switch(
    :value="customerField.enabled"
    read-only
  )
  td: fi-switch(
    :value="customerField.enabledPortal"
    read-only
  )
  td {{ classifierById("sectors", customerField.customerSector).human }}
  td
    .btn-toolbar
      .btn-group.btn-group-sm
        router-link.btn.btn-link(:to = '{ name: "KYCCustomFieldEdit", params: { id: customerField.id } }')
          i.far.fa-edit.fa-fw.fa-lg
</template>

<script>
import FiSwitch from '@/components/FiSwitch'
import { mapGetters } from 'vuex'

export default {
  name: 'k-custom-field-list-item',
  components: { FiSwitch },
  props: {
    customerField: {
      type: Object,
      required: true
    },
    customField: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapGetters('classifiers', ['classifierById'])
  }
}
</script>

<style scoped lang="scss">
.handle {
  &::before {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f7a4";
    cursor: move;
    cursor: -webkit-grabbing;
    margin-right: 15px
  }
}
</style>
